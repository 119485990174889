import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//local
import CurrentCoverageInfo from "../renewal-components/current-coverage-info/CurrentCoverageInfo";
import ProductPanal from "./product-panal/ProductPanal";
import ProductCart from "../renewal-components/product-cart/ProductCart";
import PageStepper from "../renewal-components/page-stepper/PageStepper";
import PriceMeter from "../renewal-components/price-meter/PriceMeter";
import Loading from "../shared-components/loading/Loading";

//services
import UserService from "../../../services/vo/user-service";
import WarrentyService from "../../../services/vo/warranty-service";
import CartService from "../../../services/vo/add-to-cart-service";
import AuthService from "../../../services/vo/auth-service";

//globle state
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";
import warrantyService from "../../../services/vo/warranty-service";

//utils
import AuthUtils from "../../../utils/auth-utils/AuthUtils";
import SessionStorageUtills from "../../../utils/auth-utils/SessionStorageUtills";
import { useTitle } from "../../../utils/title-utils/TabTitle";
import {
  GACustomSourcePage,
  GACustomPageView,
} from "../../../utils/ga-utils/ga-utils";
import { Utils } from "../../../App/one-platform/shared";

function ProductPage(props) {
  const renewalFlow = ["product", "owner_details", "review", "confirmation"];
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  const [currentStage, setCurrentStage] = useState(0);
  const [userFlowStatus, SetUserFlowStatus] = useState(0);
  const [urlParams, setUrlParams] = useState();
  const [nextUrl, SetNextURL] = useState("/");
  const [backUrl, SetBackURL] = useState(null);
  const [noProductAvailable, setNoProductAvailable] = useState(true);
  const [productsLoaded, setProductLoaded] = useState(false);
  const [isItemSelected, SetIsItemSelected] = useState(false);
  const [loading, SetLoading] = useState(true);
  const [Error, SetError] = useState(null);
  const [isBackButtonDisable, SetIsBackButtonDisable] = useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  if (Utils.isOnePlatform()) {
    useTitle(t("renewal.product_page.product_page_tab_title"));
  } else {
    useTitle(t("product_page_tab_title"));
  }

  useEffect(() => {
    SetLoading(true);
    saveOriginUrl();
    const params = new URLSearchParams(window.location.search);
    const vehicleNo = atob(params.get("vehicle_no"));
    const mileage = atob(params.get("mileage"));
    const insuranceId = params.get("insuranceId")
      ? atob(params.get("insuranceId"))
      : null;
    const status = params.get("status") ? atob(params.get("status")) : null;
    const backUrl = params.get("backUrl") ? atob(params.get("backUrl")) : null;

    if (params.get("origin") != "null") {
      GACustomSourcePage(
        "external",
        "referral",
        atob(params.get("origin")),
        atob(params.get("origin")).split("/").pop()
      );
      GACustomPageView(
        "campaign",
        "Product page - " + atob(params.get("origin")).split("/").pop()
      );
    } else {
      SetIsBackButtonDisable(true);
    }
    setUrlParams(params);
    SetNextURL(globalVariable.moduleData.information_page_url + "?" + params);
    SetBackURL(backUrl);
    ValidateSavedData(vehicleNo, mileage, status);

    //status = 0 - New user flow
    if (status == 0) {
      if (!AuthService.IsUrlMatchWithCultureCode()) {
        AuthService.signOut();
      }
      CheckVehicleOnBillVission(vehicleNo, mileage, status);
      SetUserFlowStatus(status);
    } else {
      AuthService.matchCultureAndRedirectURL();
      if (
        AuthUtils.CheckAuthFlowCompleted(
          globalVariable.moduleData.login_page_url,
          globalVariable.moduleData.profile_page_url
        )
      ) {
        {
          getVehicleDetails(vehicleNo, mileage, insuranceId, status);
          SetUserFlowStatus(status);
        }
      }
    }
  }, []);

  const saveOriginUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (window.location.search.includes("origin")) {
      sessionStorage.setItem("originUrl", urlParams.get("origin"));
    }
  };

  const getVehicleDetails = async (vehicleNo, mileage, insuranceId, status) => {
    try {
      const vehicleData = await UserService.getVehicleDetailsByRegNo(vehicleNo);
      if (vehicleData) {
        const vehicle = {
          regNo: vehicleNo,
          ActiveInsurance: await getActiveInsurance(
            vehicleData.data.data,
            status,
            insuranceId
          ),
          modelName:
            vehicleData.data.data[0].brandName +
            " " +
            vehicleData.data.data[0].modelName,
          insurance: vehicleData.data.data,
          mileage: mileage,
          insuranceId: insuranceId,
          UserFlowStatus: status,
        };
        sessionStorage.setItem("currentCoverage", JSON.stringify(vehicle));
        globalVariabledispatch("SET_VEHICLE_DETAILS", vehicle);
        setNoProductAvailable(false);
      } else {
        SetError("Something went wrong Please refresh");
      }
    } catch (error) {
      SetError("Something went wrong Please refresh");
      console.log(error);
    }
    SetLoading(false);
  };
  const getActiveInsurance = async (vehicleData, status, insuranceId) => {
    //status=2 - key protect &  status=3 - Extra
    var ActiveInsurance = null;
    if (status == 2 || status == 3) {
      ActiveInsurance = vehicleData.find(
        (insurance) => insurance.insuranceId == insuranceId
      );
    } else {
      ActiveInsurance = vehicleData.find(
        (insurance) =>
          insurance.type === "MBI" &&
          (insurance.status === "Active" ||
            insurance.status === "Waiting" ||
            insurance.status === "Expired")
      );
      if (ActiveInsurance == null) {
        ActiveInsurance = vehicleData.find(
          (insurance) =>
            insurance.status === "Active" ||
            insurance.status === "Waiting" ||
            insurance.status === "Expired"
        );
      }
    }
    return ActiveInsurance;
  };

  const CheckVehicleOnBillVission = async (vehicleNo, mileage, status) => {
    const language = globalVariable?.moduleData?.language;
    let LocalizeLang = "";
    switch (language) {
      case "sv-SE": {
        LocalizeLang = "sv";
        break;
      }
      case "en-gb": {
        LocalizeLang = "en";
        break;
      }
      case "nn-NO": {
        LocalizeLang = "no";
        break;
      }
      case "fi-FI": {
        LocalizeLang = "fi";
        break;
      }
      case "da-DK": {
        LocalizeLang = "da";
        break;
      }
    }
    sessionStorage.setItem("VpLang", LocalizeLang);
    try {
      const vehicleData = await WarrentyService.GetValidateNewVehicle(
        language,
        vehicleNo
      );
      if (vehicleData.data.statusCode == 1) {
        const vehicle = {
          regNo: vehicleNo,
          VehicleData: vehicleData.data,
          modelName:
            vehicleData.data?.brand + " " + vehicleData.data?.brandModel,
          mileage: mileage,
          brandGroupId: vehicleData.data?.brandGroupId,
          UserFlowStatus: status,
          enginePower: vehicleData.data?.enginePower,
          engineCapacity: vehicleData.data?.engineCapacity,
        };
        sessionStorage.setItem("currentCoverage", JSON.stringify(vehicle));
        globalVariabledispatch("SET_VEHICLE_DETAILS", vehicle);
        setNoProductAvailable(false);
      } else {
        SetError("Invalid vehicle number");
      }
    } catch (error) {
      SetError(t("common_error_msg"));
      console.log(error);
    }
    SetLoading(false);
  };

  const RestrictContinuation = () => {
    setNoProductAvailable(true);
  };

  const ValidateSavedData = (regNo, mileage, status) => {
    const cartData = CartService.getAllfromCart();
    if (cartData?.length > 0) {
      if (
        status == 0 &&
        !SessionStorageUtills.ValidateCartData(
          cartData,
          globalVariable?.moduleData?.language
        )
      ) {
        CartService.removeAllFromCart();
      }
    }
    const previousData = JSON.parse(sessionStorage.getItem("vo-info"));
    if (previousData) {
      if (
        status == 0 &&
        !SessionStorageUtills.IsSessionValid(previousData?.createDate)
      ) {
        history.push(globalVariable.moduleData.new_vehicle_page_url);
        SetLoading(true);
      } else if (
        previousData.regNo !== regNo ||
        previousData.mileage !== mileage
      ) {
        CartService.removeAllFromCart();
        sessionStorage.removeItem("vo-info");
        sessionStorage.removeItem("currentCoverage");
        sessionStorage.removeItem("vo-details");
        sessionStorage.removeItem("DibsData");

        const voinfo = {
          regNo: regNo,
          mileage: mileage,
          createDate: new Date().toDateString(),
          status: status,
        };
        sessionStorage.setItem("vo-info", JSON.stringify(voinfo));
      }
    } else {
      const voinfo = {
        regNo: regNo,
        mileage: mileage,
        createDate: new Date().toDateString(),
        language: globalVariable.moduleData.language,
        status: status,
      };
      sessionStorage.setItem("vo-info", JSON.stringify(voinfo));
    }
  };

  const onNextClicked = () => {
    SetLoading(true);
    history.push(nextUrl);
  };

  const onBackClicked = () => {
    SetLoading(true);
    if (userFlowStatus == 0) {
      if (backUrl != null) {
        history.push(backUrl);
      } else {
        history.push(globalVariable.moduleData.new_vehicle_page_callback_url);
      }
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      history.push(
        globalVariable.moduleData.warranty_page_url +
          "?lang=" +
          urlParams.get("lang") +
          "&platform=vop"
      );
    }
  };
  const onNavBack = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (window.location.search.includes("origin")) {
      window.location.assign(atob(urlParams.get("origin")));
    } else {
      onBackClicked();
    }
  };

  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div class="page-main-wrapper">
          <section class="flex space-between wrap">
            <PageStepper
              isThisStageComplete={isItemSelected}
              SetLoading={SetLoading}
              urlParams={urlParams}
              disable={noProductAvailable}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
            ></PageStepper>
            <PriceMeter></PriceMeter>
          </section>
          <section class="vehicle-summary-wrap mob-block">
            <CurrentCoverageInfo></CurrentCoverageInfo>
            <ProductCart></ProductCart>
          </section>

          {globalVariable.moduleData.product_page_rich_text_field != "" ? (
            <div class="richtext-cart-card ml-0 mb-10">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    globalVariable.moduleData.product_page_rich_text_field,
                }}
              />
            </div>
          ) : (
            ""
          )}
          {productsLoaded && !noProductAvailable ? (
            <section class="buttons-wrapper mt-40 mb-40 flex space-between flex-end display-none">
              <div className="flex v-center mob-block">
                <button class="btn-back mr-20" onClick={() => onNavBack()}>
                  {t("product_page_back_text")}
                </button>
                <button
                  class={
                    !productsLoaded || noProductAvailable || !isItemSelected
                      ? "btn-disable"
                      : "btn-primary"
                  }
                  disabled={
                    !productsLoaded || noProductAvailable || !isItemSelected
                  }
                  onClick={() => onNextClicked()}
                >
                  {t("product_page_next_text")}
                </button>
              </div>
            </section>
          ) : (
            ""
          )}
          {Error ? (
            <h3 className="error-msg">{Error}</h3>
          ) : (
            <ProductPanal
              SetIsItemSelected={SetIsItemSelected}
              userFlowStatus={userFlowStatus}
              RestrictContinuation={RestrictContinuation}
              setProductLoaded={(value) => {
                setProductLoaded(value);
              }}
              onNextClicked={onNextClicked}
            ></ProductPanal>
          )}
          {
            <section class="buttons-wrapper mt-20 mb-20 flex space-between flex-end">
              <div className="flex v-center mob-block">
                {!isBackButtonDisable && (
                  <button class="btn-back mr-20" onClick={() => onNavBack()}>
                    {t("product_page_back_text")}
                  </button>
                )}

                <button
                  class={
                    !productsLoaded || noProductAvailable || !isItemSelected
                      ? "btn-disable"
                      : "btn-primary"
                  }
                  disabled={
                    !productsLoaded || noProductAvailable || !isItemSelected
                  }
                  onClick={() => onNextClicked()}
                >
                  {t("product_page_next_text")}
                </button>
              </div>
            </section>
          }
        </div>
      )}
    </div>
  );
}

export default ProductPage;
