// ProductCard.js
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductDetails from "../product-details-popup/product-details.component";
import ProductSelect from "../product-select-popup/product-select.component";
import CartService from "../../../../../services/vo/add-to-cart-service";
import AuthService from "../../../../../services/vo/auth-service";
import defaultIcon from "../../../../../images/icon_no_image";
import OfferDiscounts from "../../../../../utils/price-utils/offerDiscounts";
import DateUtils from "../../../../../utils/date-utils/DateFormater";
import PriceFormatter from "../../../../../utils/price-utils/PriceFormatter";
import { useGlobalStore } from "../../../../../utils/global-store/vo/Vo-globalStore";

const ProductCard = ({
  productData,
  type,
  isSpecial,
  selectedMBI,
  selectedExtras,
  OnDetailsClick,
  OnSelectClick,
  onProductRemove,
  activePopup,
  OnCloseClick,
  OnSelectPopupComplete,
  sectionType,
}) => {
  const [choosenProduct, setChoosenProduct] = useState(null);
  const [globalVariable] = useGlobalStore(true);
  const { t } = useTranslation();

  const isPopupVisible = (offer, popupType) => {
    return (
      activePopup.type === popupType &&
      activePopup.productId === offer.id &&
      activePopup.section === sectionType
    );
  };

  const IsValidForDiscount = (offer) => {
    if (isSpecial !== true && offer?.type === "Extra" && selectedMBI) {
      const discountPercentage = offer.extraReducedPrice?.find(
        (x) => x.mbiInsuranceTypeId === selectedMBI.insuranceTypeId
      );
      return discountPercentage?.reducedPercentage > 0;
    }
    return false;
  };

  const isProductSelected = (product) => {
    product.isSpecial = isSpecial;
    return CartService.CheckItemAdded(product);
  };

  const HandleSelect = (offer) => {
    if (IsValidForDiscount(offer)) {
      const newOffer = { ...offer };
      newOffer.yearlyPaymentOriginal = offer.yearlyPayment;
      newOffer.monthlyPaymentOriginal = offer.monthlyPayment;
      newOffer.yearlyPayment = parseFloat(
        OfferDiscounts.getDiscountYearlyPrice(offer, selectedMBI)
      );
      newOffer.monthlyPayment = parseFloat(
        OfferDiscounts.getDiscountMonthlyPrice(offer, selectedMBI)
      );
      newOffer.discountedPrice = true;
      OnSelectClick(newOffer, isSpecial, sectionType);
    } else {
      offer.discountedPrice = false;
      OnSelectClick(offer, isSpecial, sectionType);
    }
  };

  const GetPricePeriod = (offer, selectedExtra) => {
    if (selectedMBI?.id === offer?.id && selectedMBI?.selectedperiod) {
      return selectedMBI.selectedperiod;
    } else if (
      selectedExtra?.id === offer?.id &&
      selectedExtra?.selectedperiod
    ) {
      return selectedExtra.selectedperiod;
    }
    return { price: offer.yearlyPayment, period: 12 };
  };

  const PriceText = ({ price, month }) => {
    return (
      <div className="flex price">
        <span
          className={`yearly-price notranslate ${
            month === 1 ? "large-price" : "small-price"
          }`}
        >
          {PriceFormatter.getCurrencyFormat(t("language")).format(price)}{" "}
          {AuthService.getCurrencyCode(t("language"))}
          <span className="month mr-5">
            / {month === 1 ? t("product_month_text") : t("product_months_text")}
          </span>
        </span>
      </div>
    );
  };

  const GetSelectedProduct = (type, offerId) => {
    return type === "Extra"
      ? selectedExtras?.find((element) => element.id === offerId)
      : selectedMBI;
  };

  const SelectedProductText = (offer) => {
    if (
      (offer?.type === "MBI" && selectedMBI) ||
      (offer?.type === "Extra" && selectedExtras?.length > 0)
    ) {
      const product = GetSelectedProduct(offer?.type, offer?.id);
      if (product) {
        const date = product?.startDate;
        const response = t("product_card_selected_text");
        const period = GetPricePeriod(offer, product).period;
        const data = {
          period,
          formatedDate: DateUtils.FormatDate(new Date(date)),
        };
        const replaceVariable = (match, inner) => data[inner] || "";
        const replaced = response.replace(/\$\{(\w+)\}/gi, replaceVariable);
        return <span className="yearly-price">{replaced}</span>;
      }
    }
    return null;
  };

  return (
    <div className="products-panel-cards wrap">
      {productData?.map((offer) => (
        <div
          onClick={() => setChoosenProduct(offer)}
          key={`${offer.id}-${sectionType}`}
          className={`mbi-card ${
            isProductSelected(offer)
              ? "mbi-card-ext-p border-selected"
              : choosenProduct?.id === offer.id
              ? "mbi-card-ext-p border-choosen"
              : "mbi-card-ext"
          }`}
        >
          <div className="icon-header-panel">
            <div className="mbi-icon gold">
              <img
                src={offer.offerBgImgURL || defaultIcon()}
                alt="mbi-image"
                onError={(e) => {
                  e.target.src = defaultIcon();
                }}
              />
            </div>
          </div>

          <div className="card-body p-10 card-parent0">
            <div className="mbi-title">{offer.pName}</div>

            {isProductSelected(offer) ? (
              <div className="price">
                <div className="flex price">{SelectedProductText(offer)}</div>
              </div>
            ) : (
              <div className="price">
                {IsValidForDiscount(offer) ? (
                  <div>
                    <div className="flex">
                      <span>
                        <del>
                          <PriceText price={offer.monthlyPayment} month={1} />
                        </del>
                      </span>
                      <span>
                        <del>
                          <PriceText
                            price={GetPricePeriod(offer).price}
                            month={GetPricePeriod(offer).period}
                          />
                        </del>
                      </span>
                    </div>
                    <div className="flex">
                      <span className="new-price">
                        <PriceText
                          price={OfferDiscounts.getDiscountMonthlyPrice(
                            offer,
                            selectedMBI
                          )}
                          month={1}
                        />
                      </span>
                      <span className="new-price">
                        <PriceText
                          price={OfferDiscounts.getDiscountYearlyPrice(
                            offer,
                            selectedMBI
                          )}
                          month={GetPricePeriod(offer).period}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <span>
                      <PriceText price={offer.monthlyPayment} month={1} />
                    </span>
                    <span>
                      <PriceText
                        price={GetPricePeriod(offer).price}
                        month={GetPricePeriod(offer).period}
                      />
                    </span>
                  </div>
                )}
              </div>
            )}

            <div className="flex mbi-btn-wrapper mt-10">
              <a
                className="btn-secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  OnDetailsClick(offer, isSpecial, sectionType);
                }}
              >
                {t("product_details_button")}
              </a>

              {isProductSelected(offer) ? (
                <a
                  className="btn-primary remove-mbi remove-btn-ext"
                  onClick={(e) => {
                    e.stopPropagation();
                    onProductRemove(offer);
                  }}
                >
                  {t("product_delete_button")}
                </a>
              ) : (
                <a
                  className="btn-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    HandleSelect(offer);
                  }}
                >
                  {t("product_select_button")}
                </a>
              )}
            </div>

            {isPopupVisible(offer, "select") && (
              <div className="!accordion-body acc-body">
                <ProductSelect
                  onCloseClick={OnCloseClick}
                  productInfo={offer}
                  OnSelectPopupComplete={OnSelectPopupComplete}
                  selectedMBI={selectedMBI}
                  sectionType={sectionType}
                />
              </div>
            )}

            {isPopupVisible(offer, "details") && (
              <div className="!accordion-body acc-body">
                <ProductDetails
                  onCloseClick={() => OnDetailsClick(null)}
                  productInfo={offer}
                  OnSelectClick={OnSelectClick}
                  onProductRemove={onProductRemove}
                  selectedMBI={selectedMBI}
                  sectionType={sectionType}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductCard;
