//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
import React from "react";
import ReactDOM from "react-dom";

//local
import VoProfileApp from "../../App/vo/vo-profile-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";

//globlestate
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const VOProfileApp = () => {

  const modData = {
    change_password_button_back:"Tillbaka",
    change_password_button_update:"Uppdatera",
    change_password_confirm_passowrd:"Bekräfta lösenord",
    change_password_current_passowrd:"Current password",
    change_password_error:"Something wrong please try again",
    change_password_error_not_match:"Lösenorden matchar inte",
    change_password_error_required:"Lösenord krävs",
    change_password_incorrect_password_error:"Incorrect password",
    change_password_new_passowrd:"Nytt lösenord",
    change_password_title:"Byt lösenord",
    home_page_url:"/mypages/home",
    login_page_url:"/vo-login",
    //missed_data_error:"Det saknas uppgifter",
    //password_update_successmessage:"Nytt lösenord har uppdaterats",
    profile_description:"<p>Det är viktigt att dina kontaktuppgifter är riktiga. Vi använder dem för att hålla dig uppdaterad gällande dina avtal hos oss. Din information skyddas enligt gällande lagstiftning. Läs mer om vår hantering av personuppgifter <a href=\"https://www.autoconcept.se/se/privacy?hsLang=sv-se\" rel=\" noopener\">här</a>.</p>",
    profile_form_address:"Adress",
    profile_form_button_change_password:"Ändra lösenord",
    profile_form_button_skip:"Hoppa över",
    profile_form_button_update_details:"Uppdatera uppgifter ",
    profile_form_city:"Ort",
    profile_form_companyName:"Företag",
    profile_form_email:"E-post",
    profile_form_email_dissabled_tooltip_text:"To change please <u><strong><a href=\"https://www.autoconcept.se/contact\" target=\"_blank\">contact AutoConcept Support</a></strong></u>",
    profile_form_error_email_invalid:"Ogiltig e-post",
    //profile_form_error_required:"Obligatoriskt ",
    profile_form_firstname:"Förnamn",
    profile_form_mobile:"Mobilnummer",
    profile_form_postalcode:"Postnummer",
    profile_form_surname:"Efternamn",
    profile_heading:"Mina uppgifter",
    profile_title:"Mina uppgifter",
    profile_update_successmessage:"Informationen har uppdaterats",
    type:"module"
  }
;

  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="product-module">
      <VoProfileApp
          portalId=""
          moduleName="profile"
          moduleData={modData}
          moduleInstance="module_160740477700225"
        />
      </div>
    </ErrorBoundary>
  );
};

export default VOProfileApp;

