import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import AuthService from "../../../services/vo/auth-service";
import Loading from "../shared-components/loading/Loading";

//global states
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";

const PasswordResetPage = ({ GoToLoginPage }) => {
  const { handleSubmit, register, errors } = useForm();
  const [globalVariable] = useGlobalStore(false);
  const [loading, SetLoading] = useState(false);
  const [successMessage, SetSuccessMesssage] = useState(null);
  const [errorMessage, SetErrorMessage] = useState(null);
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    try {
      SetErrorMessage(null);
      SetSuccessMesssage(null);
      SetLoading(true);
      const data = {
        userName: values.username,
        languageCode: t("language").toLowerCase(),
      };
      const response = await AuthService.resetPassword(data);
      if (response?.data != null && response.data.data.status) {
        SetSuccessMesssage(
          globalVariable.moduleData.reset_password_success_text
        );
        setTimeout(function () {
          GoToLoginPage(values.username);
        }, 2000);
      } else if (response?.data != null && !response.data.data.status) {
        SetErrorMessage(
          t("renewal.login_page.reset_password_invalidUser_error_text")
        );
      } else {
        SetErrorMessage(t("renewal.login_page.reset_password_error_text"));
      }
      SetLoading(false);
    } catch (error) {
      SetErrorMessage(t("renewal.login_page.reset_password_error_text"));
      console.log(error);
      SetLoading(false);
    }
  };

  return (
    <section className="pass-reset" style={{ marginTop: 20 }}>
      {loading ? <Loading></Loading> : ""}
      <h5>{t("renewal.login_page.change_password_title")}</h5>
      {successMessage != null ? (
        <div className="success">{successMessage}</div>
      ) : (
        ""
      )}
      {errorMessage != null ? (
        <div class="flex column">
          <span className="error"> {errorMessage}</span>
        </div>
      ) : (
        ""
      )}
      <div class="flex mob-block">
        <div class="mt-10 width-50">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="flex column mb-30">
              <label class="input-label mb-5">
                {t("renewal.login_page.login_username")}:
                <span className="asterisk">*</span>
              </label>
              <input
                class="input-style-2"
                type="text"
                name="username"
                ref={register({
                  required: t("renewal.login_page.login_username_error"),
                })}
              />
              <span className="error">
                {" "}
                {errors.username && errors.username.message}
              </span>
            </div>

            <div class="flex mb-30 v-center space-between">
              <div>
                <a onClick={() => GoToLoginPage()}>
                  {t("renewal.login_page.reset_password_back_text")}
                </a>
              </div>
              <button type="submit" className="btn btn-primary">
                {t("renewal.login_page.reset_password_send_text")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PasswordResetPage;
