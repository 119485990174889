import React, { useEffect, useState } from 'react';
import AuthService from '../../../../services/vo/auth-service';
import { useGlobalStore } from '../../../../utils/global-store/vo/Vo-globalStore';
import { useTranslation } from 'react-i18next';

const Consent = ({ tokenData,onPolicyAccepted }) => {
    const [globalVariable] = useGlobalStore(false);
    const { t } = useTranslation();

    const OnAccept = async(event) => {
        try{
            event.preventDefault();
          await AuthService.AcceptPolicy();
          onPolicyAccepted();
        }catch(err){
            console.log(err);
        }
    }

    return (
        <section className="pass-change">
            {/* privacy select page */}
                <section className="accept-policy mt-10">
                    <div className="main-page-title"> <h5>{t("consent_details.consent_title")}</h5></div>
                    <p>{t("consent_details.consent_description")}</p>
                    <p>{t("consent_details.consent_text")}</p>
                    <div class="flex wrap mob-block wrap">
                        <div class="mt-10 width-50 ">
                            <form>
                                <div class="flex mb-30 v-center space-between mob-block">
                                    <div><a href={t("consent_details.consent_link_url")} target="_blank">{t("consent_details.consent_link_text")}</a></div>
                                    <button type="button" className="btn btn-primary mob-btn-100" onClick={(event)=>OnAccept(event)}>{t("consent_details.consent_accept_button")}</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </section>
            {/* END privacy select page */}
        </section>
    );
}

export default Consent;