import React, { useState, useEffect } from "react";
import LogOut from "../components/vo/logout/logout";
import LoginPopup from "../components/vo/information-page/login-pop-up/login-pop-up";
import AuthService from "../services/vo/auth-service";
import { useTranslation } from "react-i18next";

const HeaderComponent: React.FC = (): JSX.Element => {
  const [loginPopup, setLoginPopup]: [
    loginPopup: boolean,
    setLoginPopup: Function
  ] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    console.log(AuthService.isLogin());

    if (AuthService.isLogin()) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [AuthService.isLogin()]);
  const onCloseOpenLogin: React.MouseEventHandler = (): void => {
    setLoginPopup(false);
  };

  const handleChangePW: React.MouseEventHandler = (): void => {
    setLoginPopup(true);
  };
  const { t }: { t: Function } = useTranslation();

  return (
    <div className="header-container-wrapper">
      <div className="parallax-mirror header-para">
        <img
          alt="parallax"
          className="parallax-slider header-slider"
          src="./Dina uppgifter - AutoConcept_files/Fordonsägare framför sin bil_1000x563-1.jpg"
        />
      </div>
      <div className="header-container container-fluid">
        <div className="row-fluid-wrapper row-depth-1 row-number-1">
          <div className="row-fluid">
            <div
              className="span12 widget-span widget-type-global_group"
              data-widget-type="global_group"
              data-x="0"
              data-w="12"
            >
              <div data-global-widget-path="generated_global_groups/45604488285.html">
                <div className="row-fluid-wrapper row-depth-1 row-number-1">
                  <div className="row-fluid">
                    <div
                      className="span12 widget-span widget-type-cell header-wrapper header-overlap"
                      data-widget-type="cell"
                      data-x="0"
                      data-w="12"
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-2">
                        <div className="row-fluid">
                          <div
                            className="span12 widget-span widget-type-cell centered ptb20 never-stack white-content nav-header"
                            data-widget-type="cell"
                            data-x="0"
                            data-w="12"
                          >
                            <div className="row-fluid-wrapper row-depth-1 row-number-3">
                              <div className="row-fluid">
                                <div
                                  className="span5 widget-span widget-type-cell never-stack"
                                  data-widget-type="cell"
                                  data-x="0"
                                  data-w="5"
                                >
                                  <div className="row-fluid-wrapper row-depth-1 row-number-4">
                                    <div className="row-fluid">
                                      <div
                                        className="span1 widget-span widget-type-custom_widget pt10 xs-pt10 display-none"
                                        data-widget-type="custom_widget"
                                        data-x="0"
                                        data-w="1"
                                      >
                                        <div
                                          id="hs_cos_wrapper_module_517581442"
                                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                          data-hs-cos-general-type="widget"
                                          data-hs-cos-type="module"
                                        >
                                          <span
                                            className="control-button menu"
                                            data-slide-menu="primary-slide-menu"
                                          >
                                            <span className="open">
                                              <i
                                                className="fa fa-bars"
                                                aria-hidden="true"
                                                style={{ fontSize: 30 }}
                                              ></i>
                                            </span>
                                            <span className="close">
                                              <i
                                                className="fa fa-bars"
                                                aria-hidden="true"
                                                style={{ fontSize: 30 }}
                                              ></i>
                                            </span>
                                            <span></span>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="span11 widget-span widget-type-logo pt10 mrgl-0"
                                        data-widget-type="logo"
                                        data-x="1"
                                        data-w="11"
                                      >
                                        <div className="cell-wrapper layout-widget-wrapper">
                                          <span
                                            id="hs_cos_wrapper_module_730159510"
                                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo"
                                            data-hs-cos-general-type="widget"
                                            data-hs-cos-type="logo"
                                          >
                                            <a
                                              id="hs-link-module_730159510"
                                              style={{
                                                borderWidth: 0,
                                                border: 0,
                                              }}
                                            >
                                              <img
                                                src="./Dina uppgifter - AutoConcept_files/AutoConcept_logo White.png"
                                                className="hs-image-widget"
                                                style={{
                                                  width: 100,
                                                  borderWidth: 0,
                                                  border: 0,
                                                }}
                                                width="100"
                                                alt="AutoConcept_logo White"
                                                title="AutoConcept_logo White"
                                                srcSet="
                                                  https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo%20White.png?width=50&amp;name=AutoConcept_logo%20White.png   50w,
                                                  https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo%20White.png?width=100&amp;name=AutoConcept_logo%20White.png 100w,
                                                  https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo%20White.png?width=150&amp;name=AutoConcept_logo%20White.png 150w,
                                                  https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo%20White.png?width=200&amp;name=AutoConcept_logo%20White.png 200w,
                                                  https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo%20White.png?width=250&amp;name=AutoConcept_logo%20White.png 250w,
                                                  https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo%20White.png?width=300&amp;name=AutoConcept_logo%20White.png 300w
                                                "
                                                sizes="(max-width: 100px) 100vw, 100px"
                                              />
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="span7 widget-span widget-type-cell never-stack"
                                  data-widget-type="cell"
                                  data-x="5"
                                  data-w="7"
                                >
                                  <div className="row-fluid-wrapper row-depth-1 row-number-5">
                                    <div className="row-fluid">
                                      <div
                                        className="span12 widget-span widget-type-cell all-inline tr"
                                        data-widget-type="cell"
                                        data-x="0"
                                        data-w="12"
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="row-fluid-wrapper row-depth-1 row-number-6">
                                    <div className="row-fluid">
                                      <div
                                        className="span12 widget-span widget-type-cell all-inline tr"
                                        data-widget-type="cell"
                                        data-x="0"
                                        data-w="12"
                                      >
                                        <div className="row-fluid-wrapper row-depth-2 row-number-1">
                                          <div className="row-fluid">
                                            <div
                                              className="span12 widget-span widget-type-custom_widget menu-bar flyouts-fade first-not-active flyouts-slide md-hidden"
                                              data-widget-type="custom_widget"
                                              data-x="0"
                                              data-w="12"
                                            >
                                              <div
                                                id="hs_cos_wrapper_module_16190068693151305"
                                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                                data-hs-cos-general-type="widget"
                                                data-hs-cos-type="module"
                                              >
                                                <span
                                                  id="hs_cos_wrapper_module_16190068693151305_"
                                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu"
                                                  data-hs-cos-general-type="widget"
                                                  data-hs-cos-type="menu"
                                                >
                                                  <div
                                                    id="hs_menu_wrapper_module_16190068693151305_"
                                                    className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                                                    role="navigation"
                                                    data-sitemap-name="default"
                                                    data-menu-id="45605257304"
                                                    aria-label="Navigation Menu"
                                                  >
                                                    <ul
                                                      role="menu"
                                                      className="visibility-hidden"
                                                    >
                                                      <li
                                                        className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                                        role="none"
                                                      >
                                                        <a
                                                          href="javascript:;"
                                                          aria-haspopup="true"
                                                          aria-expanded="false"
                                                          role="menuitem"
                                                        >
                                                          Anmäl skada
                                                        </a>
                                                        <ul
                                                          role="menu"
                                                          className="hs-menu-children-wrapper"
                                                        >
                                                          <li
                                                            className="hs-menu-item hs-menu-depth-2"
                                                            role="none"
                                                          >
                                                            <a
                                                              href="https://www.autoconcept.se/workshop/claiminfo"
                                                              role="menuitem"
                                                            >
                                                              Verkstad
                                                            </a>
                                                          </li>
                                                          <li
                                                            className="hs-menu-item hs-menu-depth-2"
                                                            role="none"
                                                          >
                                                            <a
                                                              href="https://www.autoconcept.se/vehicleowner/claiminfo"
                                                              role="menuitem"
                                                            >
                                                              Fordonsägare
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </li>
                                                      <li
                                                        className="hs-menu-item hs-menu-depth-1"
                                                        role="none"
                                                      >
                                                        <a
                                                          href="https://www.autoconcept.se/contact"
                                                          role="menuitem"
                                                        >
                                                          Kontakta oss
                                                        </a>
                                                      </li>
                                                      <li
                                                        className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                                        role="none"
                                                      >
                                                        <a
                                                          href="javascript:;"
                                                          aria-haspopup="true"
                                                          aria-expanded="false"
                                                          role="menuitem"
                                                        >
                                                          Om AutoConcept
                                                        </a>
                                                        <ul
                                                          role="menu"
                                                          className="hs-menu-children-wrapper"
                                                        >
                                                          <li
                                                            className="hs-menu-item hs-menu-depth-2"
                                                            role="none"
                                                          >
                                                            <a
                                                              href="https://www.autoconcept.se/offer"
                                                              role="menuitem"
                                                            >
                                                              Vårt erbjudande
                                                            </a>
                                                          </li>
                                                          <li
                                                            className="hs-menu-item hs-menu-depth-2"
                                                            role="none"
                                                          >
                                                            <a
                                                              href="https://www.autoconcept.se/information/governance"
                                                              role="menuitem"
                                                            >
                                                              Bolagsstyrning och
                                                              kvalitet
                                                            </a>
                                                          </li>
                                                          <li
                                                            className="hs-menu-item hs-menu-depth-2"
                                                            role="none"
                                                          >
                                                            <a
                                                              href="https://www.autoconcept.se/information/jobs"
                                                              role="menuitem"
                                                            >
                                                              Lediga tjänster
                                                            </a>
                                                          </li>
                                                          <li
                                                            className="hs-menu-item hs-menu-depth-2"
                                                            role="none"
                                                          >
                                                            <a
                                                              href="https://www.autoconcept.se/pressrum"
                                                              role="menuitem"
                                                            >
                                                              Pressrum
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row-fluid-wrapper row-depth-2 row-number-2">
                                          <div className="row-fluid">
                                            <div
                                              className="span12 widget-span widget-type-language_switcher lang-select ml10"
                                              data-widget-type="language_switcher"
                                              data-x="0"
                                              data-w="12"
                                            >
                                              <div className="cell-wrapper layout-widget-wrapper">
                                                <span
                                                  id="hs_cos_wrapper_module_365778971"
                                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_language_switcher"
                                                  data-hs-cos-general-type="widget"
                                                  data-hs-cos-type="language_switcher"
                                                >
                                                  <div className="lang_switcher_class visibility-hidden">
                                                    <div className="globe_class">
                                                      <ul className="lang_list_class">
                                                        <li>
                                                          <a
                                                            className="lang_switcher_link"
                                                            data-language="sv-se"
                                                            lang="sv-se"
                                                            href="https://www.autoconcept.se/mypages/information"
                                                          >
                                                            Svenska – Sverige
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            className="lang_switcher_link"
                                                            data-language="nb"
                                                            lang="nb"
                                                            href="https://www.autoconcept.se/nb/mypages/information"
                                                          >
                                                            Norwegian Bokmal
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {isLogin && (
                                          <div className="row-fluid-wrapper row-depth-2 row-number-3">
                                            <div className="row-fluid">
                                              <div
                                                className="span12 widget-span widget-type-custom_widget menu-bar flyouts-fade first-not-active flyouts-slide login-last"
                                                data-widget-type="custom_widget"
                                                data-x="0"
                                                data-w="12"
                                              >
                                                <div
                                                  id="hs_cos_wrapper_module_425442308"
                                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                                  data-hs-cos-general-type="widget"
                                                  data-hs-cos-type="module"
                                                >
                                                  <span
                                                    id="hs_cos_wrapper_module_425442308_"
                                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu"
                                                    data-hs-cos-general-type="widget"
                                                    data-hs-cos-type="menu"
                                                  >
                                                    <div
                                                      id="hs_menu_wrapper_module_425442308_"
                                                      className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                                                      role="navigation"
                                                      data-sitemap-name="default"
                                                      data-menu-id="22350875167"
                                                      aria-label="Navigation Menu"
                                                    >
                                                      <ul role="menu">
                                                        <li
                                                          className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                                          role="none"
                                                        >
                                                          <a
                                                            href="javascript:;"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            role="menuitem"
                                                          >
                                                            <span className="invertedLoginDependentStyle">
                                                              {t(
                                                                "menu_profile_title"
                                                              )}
                                                            </span>
                                                          </a>
                                                          <ul
                                                            role="menu"
                                                            className="hs-menu-children-wrapper"
                                                          >
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <a
                                                                className="cursor-pointer"
                                                                role="menuitem"
                                                                onClick={
                                                                  handleChangePW
                                                                }
                                                              >
                                                                {t(
                                                                  "change_password_title"
                                                                )}
                                                              </a>
                                                            </li>
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <LogOut />
                                                            </li>
                                                          </ul>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row-fluid-wrapper row-depth-1 row-number-1">
                                    <div className="row-fluid">
                                      <div
                                        className="span12 widget-span widget-type-cell all-inline tr"
                                        data-widget-type="cell"
                                        data-x="0"
                                        data-w="12"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-fluid-wrapper row-depth-1 row-number-2">
          <div className="row-fluid">
            <div
              className="span12 widget-span widget-type-global_group"
              data-widget-type="global_group"
              data-x="0"
              data-w="12"
            >
              <div data-global-widget-path="generated_global_groups/45754535215.html">
                <div className="row-fluid-wrapper row-depth-1 row-number-1">
                  <div className="row-fluid">
                    <div
                      className="span12 widget-span widget-type-custom_widget"
                      data-widget-type="custom_widget"
                      data-x="0"
                      data-w="12"
                    >
                      <div
                        id="hs_cos_wrapper_module_633970545"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="module"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="row-fluid-wrapper row-depth-1 row-number-2">
                  <div className="row-fluid">
                    <div
                      className="sticky-header-options"
                      data-linked-to=".header-wrapper"
                    >
                      <div
                        className="span12 widget-span widget-type-cell sticky-header md-hidden on"
                        style={{ visibility: "hidden" }}
                        data-widget-type="cell"
                        data-x="0"
                        data-w="12"
                      >
                        <div className="row-fluid-wrapper row-depth-1 row-number-3">
                          <div className="row-fluid">
                            <div
                              className="span12 widget-span widget-type-cell centered vmiddle never-stack"
                              data-widget-type="cell"
                              data-x="0"
                              data-w="12"
                            >
                              <div className="row-fluid-wrapper row-depth-1 row-number-4">
                                <div className="row-fluid">
                                  <div
                                    className="span5 widget-span widget-type-cell never-stack"
                                    data-widget-type="cell"
                                    data-x="0"
                                    data-w="5"
                                  >
                                    <div className="row-fluid-wrapper row-depth-1 row-number-5">
                                      <div className="row-fluid">
                                        <div
                                          className="span1 widget-span widget-type-custom_widget pt10 xs-pt10"
                                          data-widget-type="custom_widget"
                                          data-x="0"
                                          data-w="1"
                                        >
                                          <div
                                            id="hs_cos_wrapper_module_690909933"
                                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                            data-hs-cos-general-type="widget"
                                            data-hs-cos-type="module"
                                          >
                                            <span
                                              className="control-button menu"
                                              data-slide-menu="primary-slide-menu"
                                            >
                                              <span className="open">
                                                <i
                                                  className="fa fa-bars"
                                                  aria-hidden="true"
                                                  style={{ fontSize: 30 }}
                                                ></i>
                                              </span>
                                              <span className="close">
                                                <i
                                                  className="fa fa-bars"
                                                  aria-hidden="true"
                                                  style={{ fontSize: 30 }}
                                                ></i>
                                              </span>
                                              <span></span>
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          className="span11 widget-span widget-type-logo pt10 pl10 md-hidden"
                                          data-widget-type="logo"
                                          data-x="1"
                                          data-w="11"
                                        >
                                          <div className="cell-wrapper layout-widget-wrapper">
                                            <span
                                              id="hs_cos_wrapper_module_999035969"
                                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo"
                                              data-hs-cos-general-type="widget"
                                              data-hs-cos-type="logo"
                                            >
                                              <a
                                                href="http://autoconcept.se/"
                                                id="hs-link-module_999035969"
                                                style={{
                                                  borderWidth: 0,
                                                  border: 0,
                                                }}
                                              >
                                                <img
                                                  src="./Dina uppgifter - AutoConcept_files/AutoConcept_logo.png"
                                                  className="hs-image-widget"
                                                  style={{
                                                    width: 100,
                                                    borderWidth: 0,
                                                    border: 0,
                                                  }}
                                                  width="100"
                                                  alt="AutoConcept_logo"
                                                  title="AutoConcept_logo"
                                                  srcSet="
                                                    https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo.png?width=50&amp;name=AutoConcept_logo.png   50w,
                                                    https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo.png?width=100&amp;name=AutoConcept_logo.png 100w,
                                                    https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo.png?width=150&amp;name=AutoConcept_logo.png 150w,
                                                    https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo.png?width=200&amp;name=AutoConcept_logo.png 200w,
                                                    https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo.png?width=250&amp;name=AutoConcept_logo.png 250w,
                                                    https://www.autoconcept.se/hs-fs/hubfs/AutoConcept_logo.png?width=300&amp;name=AutoConcept_logo.png 300w
                                                  "
                                                  sizes="(max-width: 100px) 100vw, 100px"
                                                />
                                              </a>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="span7 widget-span widget-type-cell never-stack"
                                    data-widget-type="cell"
                                    data-x="5"
                                    data-w="7"
                                  >
                                    <div className="row-fluid-wrapper row-depth-1 row-number-6">
                                      <div className="row-fluid">
                                        <div
                                          className="span12 widget-span widget-type-cell all-inline tr"
                                          data-widget-type="cell"
                                          data-x="0"
                                          data-w="12"
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="row-fluid-wrapper row-depth-1 row-number-7">
                                      <div className="row-fluid">
                                        <div
                                          className="span12 widget-span widget-type-cell all-inline tr"
                                          data-widget-type="cell"
                                          data-x="0"
                                          data-w="12"
                                        >
                                          <div className="row-fluid-wrapper row-depth-2 row-number-1">
                                            <div className="row-fluid">
                                              <div
                                                className="span12 widget-span widget-type-custom_widget menu-bar flyouts-fade first-not-active flyouts-slide md-hidden"
                                                data-widget-type="custom_widget"
                                                data-x="0"
                                                data-w="12"
                                              >
                                                <div
                                                  id="hs_cos_wrapper_module_1619179302903557"
                                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                                  data-hs-cos-general-type="widget"
                                                  data-hs-cos-type="module"
                                                >
                                                  <span
                                                    id="hs_cos_wrapper_module_1619179302903557_"
                                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu"
                                                    data-hs-cos-general-type="widget"
                                                    data-hs-cos-type="menu"
                                                  >
                                                    <div
                                                      id="hs_menu_wrapper_module_1619179302903557_"
                                                      className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                                                      role="navigation"
                                                      data-sitemap-name="default"
                                                      data-menu-id="45605257304"
                                                      aria-label="Navigation Menu"
                                                    >
                                                      <ul role="menu">
                                                        <li
                                                          className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                                          role="none"
                                                        >
                                                          <a
                                                            href="javascript:;"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            role="menuitem"
                                                          >
                                                            Anmäl skada
                                                          </a>
                                                          <ul
                                                            role="menu"
                                                            className="hs-menu-children-wrapper"
                                                          >
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <a
                                                                href="https://www.autoconcept.se/workshop/claiminfo"
                                                                role="menuitem"
                                                              >
                                                                Verkstad
                                                              </a>
                                                            </li>
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <a
                                                                href="https://www.autoconcept.se/vehicleowner/claiminfo"
                                                                role="menuitem"
                                                              >
                                                                Fordonsägare
                                                              </a>
                                                            </li>
                                                          </ul>
                                                        </li>
                                                        <li
                                                          className="hs-menu-item hs-menu-depth-1"
                                                          role="none"
                                                        >
                                                          <a
                                                            href="https://www.autoconcept.se/contact"
                                                            role="menuitem"
                                                          >
                                                            Kontakta oss
                                                          </a>
                                                        </li>
                                                        <li
                                                          className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                                          role="none"
                                                        >
                                                          <a
                                                            href="javascript:;"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            role="menuitem"
                                                          >
                                                            Om AutoConcept
                                                          </a>
                                                          <ul
                                                            role="menu"
                                                            className="hs-menu-children-wrapper"
                                                          >
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <a
                                                                href="https://www.autoconcept.se/offer"
                                                                role="menuitem"
                                                              >
                                                                Vårt erbjudande
                                                              </a>
                                                            </li>
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <a
                                                                href="https://www.autoconcept.se/information/governance"
                                                                role="menuitem"
                                                              >
                                                                Bolagsstyrning
                                                                och kvalitet
                                                              </a>
                                                            </li>
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <a
                                                                href="https://www.autoconcept.se/information/jobs"
                                                                role="menuitem"
                                                              >
                                                                Lediga tjänster
                                                              </a>
                                                            </li>
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <a
                                                                href="https://www.autoconcept.se/pressrum"
                                                                role="menuitem"
                                                              >
                                                                Pressrum
                                                              </a>
                                                            </li>
                                                          </ul>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row-fluid-wrapper row-depth-2 row-number-2">
                                            <div className="row-fluid">
                                              <div
                                                className="span12 widget-span widget-type-language_switcher lang-select ml10"
                                                data-widget-type="language_switcher"
                                                data-x="0"
                                                data-w="12"
                                              >
                                                <div className="cell-wrapper layout-widget-wrapper">
                                                  <span
                                                    id="hs_cos_wrapper_module_356383848"
                                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_language_switcher"
                                                    data-hs-cos-general-type="widget"
                                                    data-hs-cos-type="language_switcher"
                                                  >
                                                    <div className="lang_switcher_class">
                                                      <div className="globe_class">
                                                        <ul className="lang_list_class">
                                                          <li>
                                                            <a
                                                              className="lang_switcher_link"
                                                              data-language="sv-se"
                                                              lang="sv-se"
                                                              href="https://www.autoconcept.se/mypages/information"
                                                            >
                                                              Svenska – Sverige
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              className="lang_switcher_link"
                                                              data-language="nb"
                                                              lang="nb"
                                                              href="https://www.autoconcept.se/nb/mypages/information"
                                                            >
                                                              Norwegian Bokmal
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row-fluid-wrapper row-depth-2 row-number-3">
                                            <div className="row-fluid">
                                              <div
                                                className="span12 widget-span widget-type-custom_widget menu-bar flyouts-fade first-not-active flyouts-slide login-last"
                                                data-widget-type="custom_widget"
                                                data-x="0"
                                                data-w="12"
                                              >
                                                <div
                                                  id="hs_cos_wrapper_module_232355994"
                                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                                  data-hs-cos-general-type="widget"
                                                  data-hs-cos-type="module"
                                                >
                                                  <span
                                                    id="hs_cos_wrapper_module_232355994_"
                                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu"
                                                    data-hs-cos-general-type="widget"
                                                    data-hs-cos-type="menu"
                                                  >
                                                    <div
                                                      id="hs_menu_wrapper_module_232355994_"
                                                      className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                                                      role="navigation"
                                                      data-sitemap-name="default"
                                                      data-menu-id="22350875167"
                                                      aria-label="Navigation Menu"
                                                    >
                                                      <ul role="menu">
                                                        <li
                                                          className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                                          role="none"
                                                        >
                                                          <a
                                                            href="javascript:;"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            role="menuitem"
                                                          >
                                                            <span className="invertedLoginDependentStyle">
                                                              Logga in
                                                            </span>
                                                          </a>
                                                          <ul
                                                            role="menu"
                                                            className="hs-menu-children-wrapper"
                                                          >
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <a
                                                                href="https://dnn.autoconcept.se/d-login"
                                                                role="menuitem"
                                                              >
                                                                Återförsäljare
                                                              </a>
                                                            </li>
                                                            <li
                                                              className="hs-menu-item hs-menu-depth-2"
                                                              role="none"
                                                            >
                                                              <a
                                                                href="https://www.autoconcept.se/vo-login"
                                                                role="menuitem"
                                                              >
                                                                Fordonsägare
                                                              </a>
                                                            </li>
                                                          </ul>
                                                        </li>
                                                        <li
                                                          className="hs-menu-item hs-menu-depth-1"
                                                          role="none"
                                                        >
                                                          <a
                                                            href="javascript:;"
                                                            role="menuitem"
                                                          >
                                                            <span className="loginDependentStyle">
                                                              Logga ut
                                                            </span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row-fluid-wrapper row-depth-1 row-number-1">
                                      <div className="row-fluid">
                                        <div
                                          className="span12 widget-span widget-type-cell all-inline tr"
                                          data-widget-type="cell"
                                          data-x="0"
                                          data-w="12"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-fluid-wrapper row-depth-1 row-number-3">
          <div className="row-fluid">
            <div
              className="span12 widget-span widget-type-global_group"
              data-widget-type="global_group"
              data-x="0"
              data-w="12"
            >
              <div data-global-widget-path="generated_global_groups/26314824319.html">
                <div className="row-fluid-wrapper row-depth-1 row-number-1">
                  <div className="row-fluid">
                    <div
                      id="primary-slide-menu"
                      className="span12 widget-span widget-type-cell slide-menu left w400 bg-white"
                      data-widget-type="cell"
                      data-x="0"
                      data-w="12"
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-2">
                        <div className="row-fluid">
                          <div
                            className="span12 widget-span widget-type-cell slide-menu-scroller"
                            data-widget-type="cell"
                            data-x="0"
                            data-w="12"
                          >
                            <div className="row-fluid-wrapper row-depth-1 row-number-3">
                              <div className="row-fluid">
                                <div
                                  className="span12 widget-span widget-type-cell never-stack ptb20 plr30 vmiddle bg-dark white-content"
                                  data-widget-type="cell"
                                  data-x="0"
                                  data-w="12"
                                >
                                  <div className="row-fluid-wrapper row-depth-1 row-number-4">
                                    <div className="row-fluid">
                                      <div
                                        className="span9 widget-span widget-type-rich_text"
                                        data-widget-type="rich_text"
                                        data-x="0"
                                        data-w="9"
                                      >
                                        <div className="cell-wrapper layout-widget-wrapper">
                                          <span
                                            id="hs_cos_wrapper_module_1490019842268586"
                                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                            data-hs-cos-general-type="widget"
                                            data-hs-cos-type="rich_text"
                                          >
                                            <strong>Menu</strong>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="span3 widget-span widget-type-custom_widget tr"
                                        data-widget-type="custom_widget"
                                        data-x="9"
                                        data-w="3"
                                      >
                                        <div
                                          id="hs_cos_wrapper_module_1574098108643918"
                                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                          data-hs-cos-general-type="widget"
                                          data-hs-cos-type="module"
                                        >
                                          <span
                                            className="control-button menu"
                                            data-slide-menu="primary-slide-menu"
                                          >
                                            <span className="open">
                                              <i
                                                className="fa fa-bars"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <span className="close">
                                              <i
                                                className="fa fa-close fas fa-times"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <span></span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row-fluid-wrapper row-depth-1 row-number-5">
                              <div className="row-fluid">
                                <div
                                  className="span12 widget-span widget-type-custom_widget accordion-menu"
                                  data-widget-type="custom_widget"
                                  data-x="0"
                                  data-w="12"
                                >
                                  <div
                                    id="hs_cos_wrapper_module_15826389802791212"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  >
                                    <span
                                      id="hs_cos_wrapper_module_15826389802791212_"
                                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu"
                                      data-hs-cos-general-type="widget"
                                      data-hs-cos-type="menu"
                                    >
                                      <div
                                        id="hs_menu_wrapper_module_15826389802791212_"
                                        className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                                        role="navigation"
                                        data-sitemap-name="default"
                                        data-menu-id="24827735657"
                                        aria-label="Navigation Menu"
                                      >
                                        <ul role="menu">
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <a
                                              href="https://www.autoconcept.se/home"
                                              role="menuitem"
                                            >
                                              Hem
                                            </a>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1 hs-item-has-children voOnly"
                                            role="none"
                                          >
                                            <a
                                              href="javascript:;"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              role="menuitem"
                                            >
                                              <span
                                                className="voOnly"
                                                style={{ color: "#f1007c" }}
                                              >
                                                Mina sidor
                                              </span>
                                            </a>
                                            <ul
                                              role="menu"
                                              className="hs-menu-children-wrapper"
                                            >
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 voOnly"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/mypages/home"
                                                  role="menuitem"
                                                >
                                                  <span className="voOnly">
                                                    Start
                                                  </span>
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 voOnly"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/mypages/my-profile"
                                                  role="menuitem"
                                                >
                                                  <span className="voOnly">
                                                    Mina kontaktuppgifter
                                                  </span>
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 voOnly"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/mypages/insurance-overview"
                                                  role="menuitem"
                                                >
                                                  <span className="voOnly">
                                                    Se och förläng avtal
                                                  </span>
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 voOnly"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/mypages/insurance-overview"
                                                  role="menuitem"
                                                >
                                                  <span className="voOnly">
                                                    Beställ och registrera
                                                    nyckelbricka
                                                  </span>
                                                </a>
                                              </li>
                                            </ul>
                                            <span className="expand-level"></span>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1 hs-item-has-children dealerOnly"
                                            role="none"
                                          >
                                            <a
                                              href="javascript:;"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              role="menuitem"
                                            >
                                              <span
                                                className="dealerOnly"
                                                style={{ color: "#f1007c" }}
                                              >
                                                Mina sidor
                                              </span>
                                            </a>
                                            <ul
                                              role="menu"
                                              className="hs-menu-children-wrapper"
                                            >
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 dealerOnly"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/d/welcome"
                                                  role="menuitem"
                                                >
                                                  <span className="dealerOnly">
                                                    Start
                                                  </span>
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 dealerOnly"
                                                role="none"
                                              >
                                                <a
                                                  href="https://dnn.autoconcept.se/d/insurance-registration"
                                                  role="menuitem"
                                                >
                                                  <span className="dealerOnly">
                                                    Registrera garantiförsäkring
                                                  </span>
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 dealerOnly"
                                                role="none"
                                              >
                                                <a
                                                  href="https://dnn.autoconcept.se/d/pricelist"
                                                  role="menuitem"
                                                >
                                                  <span className="dealerOnly">
                                                    Prislista
                                                  </span>
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 dealerOnly"
                                                role="none"
                                              >
                                                <a
                                                  href="https://dnn.autoconcept.se/d/products-to-sell"
                                                  role="menuitem"
                                                >
                                                  <span className="dealerOnly">
                                                    Avtalade produkter
                                                  </span>
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 dealerOnly"
                                                role="none"
                                              >
                                                <a
                                                  href="https://dnn.autoconcept.se/d/insurance-overview"
                                                  role="menuitem"
                                                >
                                                  <span className="dealerOnly">
                                                    Översikt tecknade
                                                    garantiförsäkringar
                                                  </span>
                                                </a>
                                              </li>
                                            </ul>
                                            <span className="expand-level"></span>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                            role="none"
                                          >
                                            <a
                                              href="javascript:;"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              role="menuitem"
                                            >
                                              Fordonsägare
                                            </a>
                                            <ul
                                              role="menu"
                                              className="hs-menu-children-wrapper"
                                            >
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/vehicleowner/insurance"
                                                  role="menuitem"
                                                >
                                                  Köp försäkring
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/vehicleowner/mbi"
                                                  role="menuitem"
                                                >
                                                  Garanti via fordonshandlare
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/vehicleowner/claiminfo"
                                                  role="menuitem"
                                                >
                                                  Skada på ett fordon
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2 hs-item-has-children"
                                                role="none"
                                              >
                                                <a
                                                  href="javascript:;"
                                                  role="menuitem"
                                                >
                                                  Nyckelförsäkring
                                                </a>
                                                <ul
                                                  role="menu"
                                                  className="hs-menu-children-wrapper"
                                                >
                                                  <li
                                                    className="hs-menu-item hs-menu-depth-3 voOnly"
                                                    role="none"
                                                  >
                                                    <a
                                                      href="https://www.autoconcept.se/mypages/insurance-overview"
                                                      role="menuitem"
                                                    >
                                                      <span className="voOnly">
                                                        Beställ och registrera
                                                        nyckelbricka
                                                      </span>
                                                    </a>
                                                  </li>
                                                  <li
                                                    className="hs-menu-item hs-menu-depth-3"
                                                    role="none"
                                                  >
                                                    <a
                                                      href="https://www.autoconcept.se/vehicleowner/keyprotect/register"
                                                      role="menuitem"
                                                    >
                                                      Registrera nyckelbricka
                                                    </a>
                                                  </li>
                                                  <li
                                                    className="hs-menu-item hs-menu-depth-3"
                                                    role="none"
                                                  >
                                                    <a
                                                      href="https://www.autoconcept.se/vehicleowner/keyprotect/claim"
                                                      role="menuitem"
                                                    >
                                                      Förlustanmäl nyckel
                                                    </a>
                                                  </li>
                                                </ul>
                                                <span className="expand-level"></span>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/vehicleowner/roadassist"
                                                  role="menuitem"
                                                >
                                                  Vägassistans
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/vehicleowner/rentalcar"
                                                  role="menuitem"
                                                >
                                                  Hyrbil
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/vehicleowner/complaints"
                                                  role="menuitem"
                                                >
                                                  Om du inte är nöjd
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://news.autoconcept.se/vehicleowner/news"
                                                  role="menuitem"
                                                >
                                                  AutoConcept Tipsar
                                                </a>
                                              </li>
                                            </ul>
                                            <span className="expand-level"></span>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                            role="none"
                                          >
                                            <a
                                              href="javascript:;"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              role="menuitem"
                                            >
                                              Fordonshandlare
                                            </a>
                                            <ul
                                              role="menu"
                                              className="hs-menu-children-wrapper"
                                            >
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/dealer/our-products"
                                                  role="menuitem"
                                                >
                                                  Våra produkter
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/dealer/become-dealer"
                                                  role="menuitem"
                                                >
                                                  Bli återförsäljare
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/vehicleowner/ford"
                                                  role="menuitem"
                                                >
                                                  Ford
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://news.autoconcept.se/dealer/news"
                                                  role="menuitem"
                                                >
                                                  AutoConcept Nyheter
                                                </a>
                                              </li>
                                            </ul>
                                            <span className="expand-level"></span>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                            role="none"
                                          >
                                            <a
                                              href="javascript:;"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              role="menuitem"
                                            >
                                              Verkstad
                                            </a>
                                            <ul
                                              role="menu"
                                              className="hs-menu-children-wrapper"
                                            >
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/workshop/claiminfo"
                                                  role="menuitem"
                                                >
                                                  Skadeanmälan - så här gör du
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://dnn.autoconcept.se/workshop/claimregistration"
                                                  role="menuitem"
                                                >
                                                  Registrera skada - skadekalkyl
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://news.autoconcept.se/toolbox"
                                                  role="menuitem"
                                                >
                                                  AutoConcept Toolbox
                                                </a>
                                              </li>
                                            </ul>
                                            <span className="expand-level"></span>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                            role="none"
                                          >
                                            <a
                                              href="javascript:;"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              role="menuitem"
                                            >
                                              Blockets Begagnatförsäkring för
                                              bil
                                            </a>
                                            <ul
                                              role="menu"
                                              className="hs-menu-children-wrapper"
                                            >
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/blocket/begagnatforsakring"
                                                  role="menuitem"
                                                >
                                                  Jag har köpt bil med Blockets
                                                  Begagnatförsäkring
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/blocket/annonsera/begagnatforsakring"
                                                  role="menuitem"
                                                >
                                                  Sälja bil med Blockets
                                                  Begagnatförsäkring
                                                </a>
                                              </li>
                                            </ul>
                                            <span className="expand-level"></span>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                                            role="none"
                                          >
                                            <a
                                              href="javascript:;"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              role="menuitem"
                                            >
                                              Om oss
                                            </a>
                                            <ul
                                              role="menu"
                                              className="hs-menu-children-wrapper"
                                            >
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/offer"
                                                  role="menuitem"
                                                >
                                                  Vårt erbjudande
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/information/governance"
                                                  role="menuitem"
                                                >
                                                  Bolagsstyrning och kvalitet
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/marketing-material"
                                                  role="menuitem"
                                                >
                                                  Marknadsföring
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/pressrum"
                                                  role="menuitem"
                                                >
                                                  Pressrum
                                                </a>
                                              </li>
                                              <li
                                                className="hs-menu-item hs-menu-depth-2"
                                                role="none"
                                              >
                                                <a
                                                  href="https://www.autoconcept.se/information/jobs"
                                                  role="menuitem"
                                                >
                                                  Lediga tjänster
                                                </a>
                                              </li>
                                            </ul>
                                            <span className="expand-level"></span>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <a
                                              href="https://www.autoconcept.se/contact"
                                              role="menuitem"
                                            >
                                              Kontakta oss
                                            </a>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <a
                                              href="https://www.autoconcept.se/dataprivacy"
                                              role="menuitem"
                                            >
                                              Integritetspolicy
                                            </a>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <a
                                              href="https://www.autoconcept.se/knowledge"
                                              role="menuitem"
                                            >
                                              Vanliga frågor
                                            </a>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <a
                                              href="javascript:;"
                                              role="menuitem"
                                            ></a>
                                          </li>
                                        </ul>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row-fluid-wrapper row-depth-1 row-number-6">
                  <div className="row-fluid">
                    <div
                      className="span12 widget-span widget-type-space slide-menu-overlay bg-dark o50"
                      data-widget-type="space"
                      data-x="0"
                      data-w="12"
                    >
                      <div className="cell-wrapper layout-widget-wrapper">
                        <span
                          id="hs_cos_wrapper_module_1490022072855604"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_space"
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="space"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-fluid-wrapper row-depth-1 row-number-4">
          <div className="row-fluid">
            <div
              className="span12 widget-span widget-type-custom_widget"
              data-widget-type="custom_widget"
              data-x="0"
              data-w="12"
            >
              <div
                id="hs_cos_wrapper_module_1579687983634477"
                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                data-hs-cos-general-type="widget"
                data-hs-cos-type="module"
              >
                <div className="hero-banner-wrapper content-section vcenter-outer scroll-next-theme-disc">
                  <div
                    className="hero-banner hero-format overlay-dark overlay-30 white-content vcenter-inner parallax-section natural"
                    data-parallax="scroll"
                    data-image-src="../images/VOP new head image half size.png"
                  >
                    <div
                      className="hero-content vcenter-content centered"
                      style={{ maxWidth: 850 }}
                    ></div>

                    <div className="hero-loading"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="information-module">
        {loginPopup && AuthService.isAuthorized() && (
          <LoginPopup
            onCloseOpenLogin={onCloseOpenLogin}
            hasUpdatePasswordPaneLoaded={true}
          ></LoginPopup>
        )}
      </div>
    </div>
  );
};
export default HeaderComponent;
