import React, { useEffect, useState, useRef } from "react";

//local
import CurrentCoverageInfo from "../renewal-components/current-coverage-info/CurrentCoverageInfo";
import OwnerDetailsPanal from "./owner-details-panal/OwnerDetailsPanal";
import ProductCart from "../renewal-components/product-cart/ProductCart";
import PageStepper from "../renewal-components/page-stepper/PageStepper";
import PriceMeter from "../renewal-components/price-meter/PriceMeter";
import Loading from "../shared-components/loading/Loading";
import LoginPOpUp from "./login-pop-up/login-pop-up";
import { useHistory } from "react-router-dom";

//services
import CartService from "../../../services/vo/add-to-cart-service";
import AuthService from "../../../services/vo/auth-service";
import userService from "../../../services/vo/user-service";
import EncryptionService from "../../../services/vo/encryption-service";

//globle state
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";
import { useTranslation } from "react-i18next";

//utils
import AuthUtils from "../../../utils/auth-utils/AuthUtils";
import SessionStorageUtills from "../../../utils/auth-utils/SessionStorageUtills";
import { useTitle } from "../../../utils/title-utils/TabTitle";
import { GACustomPageView } from "../../../utils/ga-utils/ga-utils";
import authService from "../../../services/vo/auth-service";

function InformationPage(props) {
  const ownerDetailsRef = useRef();
  const renewalFlow = ["product", "owner_details", "review", "confirmation"];
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  const [currentStage, setCurrentStage] = useState(1);
  const [isEmailValidating, setEmailValidating] = useState(false);
  const [urlParams, setUrlParams] = useState();
  const [nextUrl, SetNextURL] = useState("/");
  const [backUrl, SetBackUrl] = useState("/");
  const [informationCompleted, SetInformationCompleted] = useState(false);
  const [noProductAvailable, setNoProductAvailable] = useState(false);
  const [loading, SetLoading] = useState(true);
  const [isNewCustomerFlow, SetNewCustomerFlow] = useState(false);
  const [loginPopup, SetLoginPopUp] = useState(false);
  const [isnewuser, Setisnewuser] = useState(true);
  const [existinguserId, SetExistingUserId] = useState(null);
  const [isExistingUserView, SetisExistingUserView] = useState(false);
  const [findByOtherInfo, SetFindByOtherInfo] = useState(false);
  const [voDetails, SetVoDetails] = useState(null);
  const [isVoDetailsChecked, SetIsVoDetailsChecked] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  useTitle(t("vo_information_page_tab_title"));

  useEffect(() => {
    SetLoading(true);
    const params = new URLSearchParams(window.location.search);
    const vehicleNo = atob(params.get("vehicle_no"));
    const mileage = atob(params.get("mileage"));
    const status = atob(params.get("status"));
    setUrlParams(params);
    SetBackUrl(globalVariable.moduleData.product_page_url + "?" + params);
    SetNextURL(globalVariable.moduleData.checkout_page_url + "?" + params);
    ValidateSavedData(vehicleNo, mileage, status);
    if (authService.isAuthorized()) {
      sessionStorage.setItem("isEmailValid", true);
    }
    if (status == 0) {
      if (!AuthService.IsUrlMatchWithCultureCode()) {
        AuthService.signOut();
      }
      SetNewCustomerFlow(true);
    } else {
      AuthUtils.CheckAuthFlowCompleted(
        globalVariable.moduleData.login_page_url,
        globalVariable.moduleData.profile_page_url
      );
    }
    SetLoading(false);

    if (status != 0) {
      AuthService.matchCultureAndRedirectURL();
    }
    GetVoDetails();
    if (params.get("origin") != "null") {
      GACustomPageView(
        "campaign",
        "Customer information page - " +
          atob(params.get("origin")).split("/").pop()
      );
    }
  }, []);

  const GetVoDetails = async () => {
    const storedVoDetails = JSON.parse(sessionStorage.getItem("vo-details"));
    if (storedVoDetails) {
      try {
        const decryptedData = await EncryptionService.DecryptVoDetails(
          storedVoDetails.token
        );
        //decryptedData?.data={} if token expired
        if (
          decryptedData &&
          Object.entries(decryptedData?.data).length !== 0 &&
          SessionStorageUtills.CheckIsLanguageMatching(
            storedVoDetails?.language,
            globalVariable?.moduleData?.language
          )
        ) {
          SetVoDetails(decryptedData.data);
          SetIsVoDetailsChecked(true);
          return decryptedData.data;
        } else {
          sessionStorage.removeItem("vo-details");
          SetIsVoDetailsChecked(true);
          return null;
        }
      } catch (error) {
        console.log(error);
        sessionStorage.removeItem("vo-details");
        SetIsVoDetailsChecked(true);
        return null;
      }
    } else {
      SetIsVoDetailsChecked(true);
    }
  };

  const ValidateSavedData = (regNo, mileage, status) => {
    const cartData = CartService.getAllfromCart();
    if (cartData?.length > 0) {
      if (
        status == 0 &&
        !SessionStorageUtills.ValidateCartData(
          cartData,
          globalVariable?.moduleData?.language
        )
      ) {
        CartService.removeAllFromCart();
      }
    }
    const previousData = JSON.parse(sessionStorage.getItem("vo-info"));
    if (previousData) {
      if (
        status == 0 &&
        !SessionStorageUtills.IsSessionValid(previousData?.createDate)
      ) {
        SetLoading(true);
        history.push(globalVariable.moduleData.new_vehicle_page_url);
      }
      if (previousData.regNo !== regNo || previousData.mileage !== mileage) {
        CartService.removeAllFromCart();
        sessionStorage.removeItem("vo-info");
        sessionStorage.removeItem("currentCoverage");
        sessionStorage.removeItem("vo-details");
        sessionStorage.removeItem("DibsData");
        const voinfo = {
          regNo: regNo,
          mileage: mileage,
          createDate: new Date().toDateString(),
        };
        sessionStorage.setItem("vo-info", JSON.stringify(voinfo));
      }
    } else {
      const voinfo = {
        regNo: regNo,
        mileage: mileage,
        createDate: new Date().toDateString(),
      };
      sessionStorage.setItem("vo-info", JSON.stringify(voinfo));
    }
  };

  const onNextClicked = async (voDetails) => {
    SetLoading(true);
    const storedVoDetails = voDetails;
    SetVoDetails(storedVoDetails);
    if (isNewCustomerFlow) {
      var isAuthorized = AuthService.isAuthorized();
      if (isAuthorized && AuthService.getLoginFlow() == "NewUser") {
        history.push(nextUrl);
      } else if (!isAuthorized) {
        CreateNewUser(storedVoDetails);
      } else {
        history.push(nextUrl);
      }
    } else {
      SetLoading(true);
      history.push(nextUrl);
    }
  };

  const mapVoDetailstoUpdate = (userData, isCompany) => {
    const profile = {};
    profile.email = userData?.email;
    profile.city = userData?.city;
    profile.zipCode = userData?.postcode;
    profile.address = userData?.address;
    profile.lastName = userData?.surname;
    profile.personalnumber = userData?.personalnumber;
    profile.mobile = userData?.mobile_number;
    if (isCompany == "False") {
      profile.firstName = userData?.firstname;
    }
    return profile;
  };

  const logNewUser = async (userObj) => {
    const data = {
      username: userObj.userName,
      password: userObj.code,
      grant_type: "password",
      client_id: "AutoconceptWeb",
      culture_code: globalVariable.moduleData.language,
      user_type: 3,
    };
    const response = await AuthService.loginNewCustomer(
      data,
      userObj.isNewUser
    );
    response.data.cultureCode = globalVariable.moduleData.language;
    response.data.loginflow = "NewUser";
    return response;
  };

  const updateUserDetailsWithOTP = async (storedVoDetails, response) => {
    var mappedData = mapVoDetailstoUpdate(
      storedVoDetails,
      response?.data?.isCompany
    );
    await userService.UpdateUserDetailsWithOTP(
      mappedData,
      response?.data?.access_token
    );
  };
  const updateNewPassCode = async (userObj, response) => {
    const dataCode = {
      newPassword: userObj.code,
    };
    const res = await AuthService.UpdateNewPassword(
      dataCode,
      response?.data?.access_token
    );
    if (res.data != null) {
      response.data.isFirstTimePasswordChanged = "True";
      response.data.isPolicyAccepted = "True";
      await AuthService.storeTokenInfo(response.data);
      history.push(nextUrl);
    }
  };

  const updateNewPassCodeSilentLogin = async (userObj, response) => {
    const dataCode = {
      newPassword: userObj.code,
    };
    const res = await AuthService.UpdateNewPasswordSilentLogin(
      dataCode,
      response?.data?.access_token
    );
    if (res.data != null) {
      response.data.isFirstTimePasswordChanged = "False";
      response.data.isPolicyAccepted = "False";
      await AuthService.storeTokenInfo(response.data);
      history.push(nextUrl);
    }
  };

  const getUserLoggedSilently = async (userObj, storedVoDetails) => {
    let response = await logNewUser(userObj);
    await updateUserDetailsWithOTP(storedVoDetails, response);
    updateNewPassCode(userObj, response);
    //updateNewPassCodeSilentLogin(userObj, response);// Commented update pw on silent login due to pw change screen absency
  };

  const CreateNewUser = async (storedVoDetails) => {
    try {
      const voData = await getCustomerInformation(storedVoDetails);
      if (voData != null) {
        // Call Create New Customer
        const resnewCustomer = await userService.CreateNewCustomer(voData);
        if (resnewCustomer?.data != null) {
          if (!resnewCustomer.data.isUserExist) {
            sessionStorage.setItem("isNewUser", "1");
            Setisnewuser(true);
            SetLoginPopUp(false);
            SetFindByOtherInfo(false);
            SetLoading(true);
            getUserLoggedSilently(
              {
                userName: resnewCustomer.data.username,
                code: resnewCustomer.data.code,
                isNewUser: true,
              },
              storedVoDetails
            );
          } else if (
            resnewCustomer.data.isUserExist &&
            !resnewCustomer.data.isFirstTimeLogin
          ) {
            sessionStorage.setItem("isNewUser", "0");
            if (resnewCustomer.data.findByOtherInfo) {
              SetFindByOtherInfo(true);
            } else {
              SetFindByOtherInfo(false);
            }
            Setisnewuser(false);
            SetLoginPopUp(true);
            SetLoading(false);
          } else {
            SetExistingUserId(resnewCustomer.data.username);
            Setisnewuser(false);
            SetLoginPopUp(true);
            SetLoading(false);
            SetFindByOtherInfo(false);
          }
        } else {
          alert("error, while creating account!");
          SetLoading(false);
        }
      } else {
        alert("error, while creating account!");
        SetLoading(false);
      }
    } catch (error) {
      console.log(error);
      SetLoading(false);
      alert("error, while creating account!");
    }
  };

  const onClickOpenLoginScreen = (e) => {
    e.preventDefault();
    SetExistingUserId(null);
    Setisnewuser(false);
    SetisExistingUserView(true);
    SetLoginPopUp(true);
  };

  const getCustomerInformation = async (storedVoDetails) => {
    if (storedVoDetails != null) {
      return {
        customerId: "0",
        IdentificationNumber: storedVoDetails?.personalnumber,
        email: storedVoDetails?.email,
        city: storedVoDetails?.city,
        zipCode: storedVoDetails?.postcode,
        address: storedVoDetails?.address,
        lastName: storedVoDetails?.surname,
        firstName: storedVoDetails?.firstname,
        MobilePhone: storedVoDetails?.mobile_number,
        languageCode: globalVariable.moduleData.language,
        channel: "Vendor",
      };
    }
    return null;
  };

  const onBackClicked = () => {
    SetLoading(true);
    history.push(backUrl);
  };

  const onCloseOpenLogin = () => {
    // e.preventDefault();
    SetLoading(true);
    Setisnewuser(true);
    SetisExistingUserView(false);
    SetLoginPopUp(false);
    SetLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div class="page-main-wrapper">
          <section class="flex space-between wrap">
            <PageStepper
              isThisStageComplete={informationCompleted}
              SetLoading={SetLoading}
              urlParams={urlParams}
              disable={noProductAvailable}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
            ></PageStepper>
            <PriceMeter></PriceMeter>
          </section>
          <section class="vehicle-summary-wrap mob-block">
            <CurrentCoverageInfo></CurrentCoverageInfo>
            <ProductCart></ProductCart>
          </section>
          {/* already have account */}
          {isNewCustomerFlow && !AuthService.isAuthorized() ? (
            <div className="vo-have-account mb-10 mt-10">
              {t("information_already_account")}{" "}
              <a
                href="javascript: void(0)"
                onClick={(e) => onClickOpenLoginScreen(e)}
              >
                {t("login_existinguser_login_button")}
              </a>
            </div>
          ) : (
            ""
          )}
          {/* already have account */}
          <OwnerDetailsPanal
            ref={ownerDetailsRef}
            SetInformationCompleted={SetInformationCompleted}
            SubmitDetails={onNextClicked}
            isNewCustomerFlow={isNewCustomerFlow}
            voDetails={voDetails}
            isVoDetailsChecked={isVoDetailsChecked}
            setEmailValidating={setEmailValidating}
          ></OwnerDetailsPanal>
          {currentStage != 3 ? (
            <section class="buttons-wrapper mt-20 mb-20 flex space-between flex-end">
              <div className="flex v-center mob-block">
                <button
                  type="button"
                  class="btn-back mr-20"
                  onClick={() => onBackClicked()}
                >
                  {t("information_page_back_text")}
                </button>
                <button
                  class={`${
                    informationCompleted &&
                    sessionStorage.getItem("isEmailValid") == "true"
                      ? "btn-primary"
                      : "btn-disable"
                  }`}
                  disabled={
                    !informationCompleted &&
                    sessionStorage.getItem("isEmailValid") == "false"
                  }
                  onClick={() => {
                    ownerDetailsRef.current.SubmitDetails();
                  }}
                >
                  <div
                    className={isEmailValidating ? "loader" : "hide-element"}
                  ></div>
                  <span className={isEmailValidating ? "hide-element" : ""}>
                    {t("information_page_next_text")}
                  </span>
                </button>
                {/* <button type="button" class="btn-primary" onClick={() => { onOpenLoginPage() }}>Next</button> */}
              </div>
            </section>
          ) : (
            ""
          )}
        </div>
      )}
      {loginPopup ? (
        <LoginPOpUp
          onCloseOpenLogin={onCloseOpenLogin}
          SetMainPageLoading={SetLoading}
          isnewuser={isnewuser}
          voDetails={voDetails}
          existinguserId={existinguserId}
          isExistingUserView={isExistingUserView}
          nextUrl={nextUrl}
          findByOtherInfo={findByOtherInfo}
        ></LoginPOpUp>
      ) : (
        ""
      )}
    </div>
  );
}

export default InformationPage;
