import React from "react";
import { useTranslation } from "react-i18next";

const FooterComponent = () => {
  const { t }: { t: Function } = useTranslation();
  return (
    <div className="footer-container-wrapper">
      <div className="footer-container container-fluid">
        <div className="row-fluid-wrapper row-depth-1 row-number-1">
          <div className="row-fluid">
            <div
              className="span12 widget-span widget-type-global_group"
              data-widget-type="global_group"
              data-x="0"
              data-w="12"
            >
              <div data-global-widget-path="generated_global_groups/21428508467.html">
                <div className="row-fluid-wrapper row-depth-1 row-number-1">
                  <div className="row-fluid">
                    <div
                      className="span12 widget-span widget-type-custom_widget"
                      data-widget-type="custom_widget"
                      data-x="0"
                      data-w="12"
                    >
                      <div
                        id="hs_cos_wrapper_module_1580153814893221"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="module"
                      >
                        <div className="row-fluid-wrapper pl50 pt10 pb10 bg-dark white-content">
                          <div className="row-fluid">
                            <div className="span4 widget-span widget-type-cell never-stack">
                              <div>
                                <div>
                                  <p>
                                    <strong>{t("footer_title")}</strong>
                                  </p>
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: t("footer_address"),
                                  }}
                                ></div>
                                <br />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: t("footer_org_number"),
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="span4 widget-span widget-type-cell never-stack tc ml0 pt5">
                              <a
                                href={t("footer_trustpilot_link")}
                                target="_blank"
                                style={{ textDecoration: "none" }}
                              >
                                <span className="footer-rl1"></span>
                              </a>
                              <a
                                href="https://www.facebook.com/773504036152268"
                                target="_blank"
                                style={{ textDecoration: "none" }}
                              >
                                <span className="footer-rl2"></span>
                              </a>
                            </div>
                            <div className="span4 widget-span widget-type-cell never-stack tl pl150">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t("footer_opening"),
                                }}
                              ></div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t("footer_contact"),
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="full-width tc pb10 bg-dark white-content">
                          <p>
                            Copyright © 2025 AutoConcept Insurance AB. All
                            Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-fluid-wrapper row-depth-1 row-number-2">
          <div className="row-fluid">
            <div
              className="span12 widget-span widget-type-global_group"
              data-widget-type="global_group"
              data-x="0"
              data-w="12"
            >
              <div
                className=""
                data-global-widget-path="generated_global_groups/21429363060.html"
              >
                <div className="row-fluid-wrapper row-depth-1 row-number-1">
                  <div className="row-fluid">
                    <div
                      className="span12 widget-span widget-type-custom_widget"
                      data-widget-type="custom_widget"
                      data-x="0"
                      data-w="12"
                    >
                      <div
                        id="hs_cos_wrapper_module_1574097996565832"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="module"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
