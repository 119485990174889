import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loading from "../shared-components/loading/Loading";
import PasswordChange from "../shared-components/password-change/PasswordChange";
import { useTitle } from "../../../utils/title-utils/TabTitle";
//global states
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";

//service
import UserService from "../../../services/vo/user-service";
import AuthService from "../../../services/vo/auth-service";

//utils
import AuthUtils from "../../../utils/auth-utils/AuthUtils";

const COUNTDOWN_TIME = 30;

const ProfilePage = () => {
  const [globalVariable] = useGlobalStore(false);
  const { handleSubmit, register, errors, reset, formState } = useForm({
    mode: "onBlur",
  });
  const [currentProfileData, SetCurrentProfileData] = useState(null);
  const [changePasswordActive, SetChangePasswordActive] = useState(false);
  const [loading, SetLoading] = useState(false);
  const [isDataMissing, SetIsDataMissing] = useState(false);
  const [countdownTimer, SetcountdownTimer] = useState(COUNTDOWN_TIME);
  const [isCoundownActive, SetIsCoundownActive] = useState(false);
  const [isPasswordChangeSuccess, SetIsPasswordChangeSuccess] = useState(false);
  const [isEmailEditable, SetIsEmailEditable] = useState(false);
  const [isToolTipVisible, SetIsToolTipVisible] = useState(false);
  const history = useHistory();
  const wrapperRef = useRef(null);
  const { t } = useTranslation();

  useTitle(t("edit_profile"));
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      SetIsToolTipVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    const params = new URLSearchParams(window.location.search);
    const dataMissing = atob(params.get("missed_data"));
    SetIsDataMissing(dataMissing == "true");
    SetLoading(true);
    if (!AuthService.isAuthorized()) {
      history.push(globalVariable.moduleData.login_page_url);
    } else {
      getProfile();
    }
    SetIsEmailEditable(!AuthUtils.CheckEmailFieldEditable());
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (isCoundownActive) {
      setTimeout(() => {
        if (countdownTimer > 0) {
          SetcountdownTimer(countdownTimer - 1);
        } else {
          SetIsCoundownActive(false);
          SetcountdownTimer(COUNTDOWN_TIME);
        }
      }, 1000);
    }
  }, [isCoundownActive, countdownTimer]);

  const getProfile = async () => {
    const token = AuthService.getTokenInfo();
    try {
      const profileData = await UserService.getUserProfile(token.userName);
      SetCurrentProfileData(profileData.data.data);
      reset({
        email: profileData.data.data.email,
        city: profileData.data.data.city,
        zipCode: profileData.data.data.zipCode,
        address: profileData.data.data.address,
        lastName: profileData.data.data.lastName,
        firstname: profileData.data.data.firstName,
      });
      SetLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onProfileUpdate = async (values) => {
    SetLoading(true);
    // const token=AuthService.getTokenInfo();
    const profile = {};
    profile.email = values.email;
    profile.city = values.city;
    profile.zipCode = values.zipCode;
    profile.address = values.address;
    profile.lastName = values.lastName;
    if (!CheckIsCompany()) {
      profile.firstName = values.firstname;
    }

    try {
      await UserService.UpdateUserProfile(profile);
      if (isDataMissing) {
        const currentToken = AuthService.getTokenInfo();
        currentToken.isValidCustomerInfo = "True";
        AuthService.storeTokenInfo(currentToken);

        history.push(globalVariable.moduleData.login_page_url);
      } else {
        SetIsPasswordChangeSuccess(false);
        SetIsCoundownActive(true);
        getProfile();
        SetLoading(false);
      }
    } catch (error) {
      console.log(error);
      SetLoading(false);
    }
  };

  const HandlePasswordChangeButton = (res) => {
    if (res == true) {
      SetIsPasswordChangeSuccess(true);
      SetIsCoundownActive(true);
    }
    SetChangePasswordActive(!changePasswordActive);
  };

  const HandleSkipButton = () => {
    SetLoading(true);
    history.push(globalVariable.moduleData.home_page_url);
  };

  const ValidateEmail = async (email) => {
    if (currentProfileData.email === email) {
      return true;
    } else {
      try {
        const result = await UserService.ValidateEmail(email);
        return result.data.isValid;
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  };

  const CheckIsCompany = () => {
    return AuthService.getIsCompany() == "True";
  };

  const onBackClicked = () => {
    history.goBack();
  };

  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div>
          <section className="profile-container mid-container">
            <div className="main-page-title">
              <h1 className="m-l-0" style={{ marginLeft: 0 }}>
                {t("profile.profile_heading")}
              </h1>
            </div>
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("profile.profile_description"),
                }}
              />
            </p>
            {isDataMissing ? (
              <h3 className="error-msg">{t("profile.missed_data_error")}</h3>
            ) : (
              isDataMissing
            )}
            <div class="flex mob-block wrap">
              {changePasswordActive ? (
                <PasswordChange
                  onPasswordChange={HandlePasswordChangeButton}
                  type="profile"
                ></PasswordChange>
              ) : (
                <div class="mt-30 width-100">
                  {isCoundownActive ? (
                    <div class="flex column mb-30">
                      {isPasswordChangeSuccess ? (
                        <div class="tag-success">
                          {t("profile.password_update_successmessage")}
                        </div>
                      ) : (
                        <div class="tag-success">
                          {t("profile.profile_update_successmessage")}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <form onSubmit={handleSubmit(onProfileUpdate)}>
                    {/* <h5>{globalVariable.moduleData.profile_title}</h5> */}
                    {CheckIsCompany() ? (
                      ""
                    ) : (
                      <div class="flex column mb-30">
                        <label class="input-label mb-5">
                          {t("profile.profile_form_firstname")}:
                          <span className="asterisk">*</span>
                        </label>
                        <input
                          class="input-style-2"
                          name="firstname"
                          type="text"
                          ref={register({
                            required: (
                              <div class="form-validation mt-5">
                                *{t("profile.profile_form_error_required")}
                              </div>
                            ),
                          })}
                        />
                        {errors.firstname && errors.firstname.message}
                      </div>
                    )}
                    <div class="flex column mb-30">
                      <label class="input-label mb-5">
                        {CheckIsCompany()
                          ? t("profile.profile_form_companyName")
                          : t("profile.profile_form_surname")}
                        : <span className="asterisk">*</span>
                      </label>
                      <input
                        class="input-style-2"
                        name="lastName"
                        type="text"
                        ref={register({
                          required: (
                            <div class="form-validation mt-5">
                              *{t("profile.profile_form_error_required")}
                            </div>
                          ),
                        })}
                      />
                      {errors.lastName && errors.lastName.message}
                    </div>
                    <div class="flex column mb-30">
                      <label class="input-label mb-5">
                        {t("profile.profile_form_address")}:{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <input
                        class="input-style-2"
                        name="address"
                        type="text"
                        ref={register({
                          required: (
                            <div class="form-validation mt-5">
                              *{t("profile.profile_form_error_required")}
                            </div>
                          ),
                        })}
                      />
                      {errors.address && errors.address.message}
                    </div>
                    <div class="flex column mb-30">
                      <label class="input-label mb-5">
                        {t("profile.profile_form_postalcode")}:{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <input
                        class="input-style-2"
                        name="zipCode"
                        type="text"
                        ref={register({
                          required: (
                            <div class="form-validation mt-5">
                              *{t("profile.profile_form_error_required")}
                            </div>
                          ),
                        })}
                      />
                      {errors.zipCode && errors.zipCode.message}
                    </div>
                    <div class="flex column mb-30">
                      <label class="input-label mb-5">
                        {t("profile.profile_form_city")}:{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <input
                        class="input-style-2"
                        name="city"
                        type="text"
                        ref={register({
                          required: (
                            <div class="form-validation mt-5">
                              *{t("profile.profile_form_error_required")}
                            </div>
                          ),
                        })}
                      />
                      {errors.city && errors.city.message}
                    </div>
                    <div class="flex column mb-30">
                      <label class="input-label mb-5">
                        {t("profile.profile_form_email")}:{" "}
                        <span className="asterisk">* </span>
                        {!isEmailEditable ? (
                          <span>
                            <span
                              className="email-tooltip-icon"
                              ref={wrapperRef}
                              onClick={() =>
                                SetIsToolTipVisible(!isToolTipVisible)
                              }
                            >
                              {" "}
                            </span>
                            {isToolTipVisible ? (
                              <div className="email-tooltip-box">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: t(
                                      "profile.profile_form_email_dissabled_tooltip_text"
                                    ),
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        class="input-style-2"
                        name="email"
                        type="text"
                        disabled={!isEmailEditable}
                        ref={register({
                          required: (
                            <div class="form-validation mt-5">
                              *{t("profile.profile_form_error_required")}
                            </div>
                          ),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: (
                              <div class="form-validation mt-5">
                                *{t("profile.profile_form_error_email_invalid")}
                              </div>
                            ),
                          },
                          validate: (input) => ValidateEmail(input),
                        })}
                      />
                      {errors.email && errors.email.message}
                      {errors.email && errors.email.type === "validate" && (
                        <div class="form-validation mt-5">
                          This email address already exists
                        </div>
                      )}
                    </div>

                    <div class="flex mb-30 v-center flex-end mob-block">
                      <button
                        class="btn btn-back mob-btn-100 mr-20"
                        onClick={() => onBackClicked()}
                      >
                        {t("product_page_back_text")}
                      </button>
                      {isDataMissing ? (
                        <a
                          className="btn btn-primary mob-btn-100 mr-20"
                          name="skip"
                          onClick={HandleSkipButton}
                        >
                          {t("profile.profile_form_button_skip")}
                        </a>
                      ) : (
                        <button
                          className="btn-primary mob-btn-100 mr-20"
                          name="passwordChange"
                          onClick={HandlePasswordChangeButton}
                        >
                          {t("profile.profile_form_button_change_password")}
                        </button>
                      )}
                      <button
                        type="submit"
                        name="submit"
                        className={
                          formState.isValid
                            ? "btn-primary mob-btn-100"
                            : "btn-disable mob-btn-100"
                        }
                        disabled={!formState.isValid}
                      >
                        {t("profile.profile_form_button_update_details")}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
